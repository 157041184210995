import { createContext, useReducer } from "react";
import uiReducer, { INITIAL_STATE } from "@/lib/ui/reducers/uiReducer";

export const uiContext = createContext();

const UiProvider = ({ children }) => {
  const [uiState, uiDispatch] = useReducer(uiReducer, INITIAL_STATE);
  return (
    <uiContext.Provider value={{ uiState, uiDispatch }}>
      {children}
    </uiContext.Provider>
  );
};

export default UiProvider;
