import EndavoLogo from "@/images/endavo_logo.png";
import IconLive from "@/images/icon_live.png";

const STREAMING_FAITH_SKIN_NAME = "streamingFaith";

const streamingFaithGroupId =
  process.env.REACT_APP_STAGE === "production" ? 11 : 28;

const themeNamesByGroupId = {
  1: "default",
  [streamingFaithGroupId]: STREAMING_FAITH_SKIN_NAME
};

const themes = {
  default: {
    logo: EndavoLogo
  },
  STREAMING_FAITH_SKIN_NAME: {
    logo: IconLive
  }
};

export const getTheme = (groupId) => {
  const themeName = themeNamesByGroupId?.[groupId] || "default";

  switch (themeName) {
    case STREAMING_FAITH_SKIN_NAME:
      return themes.STREAMING_FAITH_SKIN_NAME;
    default:
      return themes.default;
  }
};
