import { SET_OPEN_MODAL, HIDE_TOAST, SHOW_TOAST } from "../actions/actionTypes";

export const INITIAL_STATE = {
  modals: {},
  toast: { show: false, msg: "" }
};

const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_MODAL: {
      const params = action.params ? action.params : {};
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.entity]: {
            params,
            isOpen: action.isOpen
          }
        }
      };
    }
    case SHOW_TOAST:
      const { msg = action.payload, type } = action.payload;
      return {
        ...state,
        toast: {
          show: true,
          msg,
          type
        }
      };
    case HIDE_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          show: false
        }
      };
    default:
      return state;
  }
};

export default uiReducer;
