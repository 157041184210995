import { lazy, Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { ThemeProvider } from "styled-components";
import { getTheme } from "./theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import UiProvider from "./components/UiProvider";

/* 
  updated use-react-query to version 2, 
  which drops dependency on query-string and uses native URLSearchParams which doesn’t support null
  we don't use null anywhere in filters but we should keep in mind
  if we have to use it we can install again query-string and pass it's functions to QueryParamProvider
*/

/* 
  We need to combine all routes in single place to use createBrowserRouter..
  in order to benefit from loader API of ver 6 of the router...
*/

const queryClient = new QueryClient();

const StoreRouterLocaleProvider = ({ children }) => (
  <UiProvider>
    <Suspense fallback={<div className="loader" />}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <StrictMode>{children}</StrictMode>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  </UiProvider>
);

const App = lazy(() => import("./App"));
const Header = lazy(() => import("./components/header/Header"));
const QuickLinks = lazy(() => import("@/lib/ui/components/quickLinks"));

(function main() {
  const mainReactEl = document.querySelector(".react-content");
  const header = document.getElementById("react-header");
  const quickLinks = document.getElementById("react-quick-links");

  let groupId = null;
  if (mainReactEl) {
    groupId = mainReactEl.getAttribute("data-groupId");
  }

  if (header) {
    const headerRoot = createRoot(header);
    headerRoot.render(
      <StoreRouterLocaleProvider>
        <Header mainReactInDom={!!mainReactEl} />
      </StoreRouterLocaleProvider>
    );
  }

  if (mainReactEl) {
    const mainRoot = createRoot(mainReactEl);
    mainRoot.render(
      <StoreRouterLocaleProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ThemeProvider theme={getTheme(groupId)}>
            <App groupId={groupId} />
          </ThemeProvider>
        </QueryParamProvider>
      </StoreRouterLocaleProvider>
    );
  }
  if (quickLinks) {
    const quickLinksRoot = createRoot(quickLinks);
    quickLinksRoot.render(
      <StoreRouterLocaleProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QuickLinks isInPhpEnv />
        </QueryParamProvider>
      </StoreRouterLocaleProvider>
    );
  }
})();
